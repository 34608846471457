@import '../../styles/variables';

.page {
  &__summary {
    display: block;
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }

  &__icon {
    display: block;
    margin: 0 auto 1.5rem;
  }

  &__list {
    margin: 0 0 3rem;
    padding-left: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item {
    font-size: 1.25rem;
    padding-left: 1rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $color-blue-1;
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &::marker {
      color: $color-primary-1;
    }
  }
}
