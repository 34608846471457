@import '../../styles/variables';
@import '../../styles/mixins/breakpoints';

.section {
  box-sizing: border-box;
  max-width: 72.5rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1.5rem;

  @include breakpoint-md {
    padding: 0 5rem;
  }
}

.homepage {
  // Section with image.

  &__background {
    padding: 5rem 0;
    background-image: url('~/public/images/homepage-background.jpg');
    background-size: cover;
    background-position: bottom;
    background-color: #dfe5e8;

    @include breakpoint-md {
      padding: 10rem 0;
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-weight: 800;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 3.75rem;

    @include breakpoint-lg {
      font-size: 2rem;
    }
  }

  &__contact {
    text-transform: uppercase;
    text-decoration: none;
    padding: 1rem 2.25rem;
    border: 0.25rem solid $color-primary-4;
    color: $color-primary-4;
    font-size: 1.25rem;
    font-weight: 700;
    outline: none;
    transition: all 0.15s;

    &:hover {
      background: rgba($color-grey-2, 0.5);
    }

    &:focus {
      background: rgba($color-grey-6, 0.5);
    }
  }

  // Two cards section.

  &__cards {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;

    @include breakpoint-md {
      flex-direction: row;
      align-items: unset;
      justify-content: center;
    }
  }

  &__card {
    display: flex;
    align-items: center;
    max-width: 26rem;
    border: 0.125rem solid $color-grey-1;
    padding: 2rem 2.5rem;
    font-weight: 500;
    font-size: 1rem;
    color: $color-primary-1;
    box-sizing: border-box;

    &:first-of-type {
      margin-bottom: 1.875rem;
    }

    @include breakpoint-md {
      width: 50%;

      &:first-of-type {
        margin-right: 1.875em;
        margin-bottom: 0;
      }

      &:last-of-type {
        margin-left: 1.875rem;
      }
    }

    > img {
      flex-shrink: 0;
      margin-right: 2rem;
    }
  }

  // Services menu.

  &__list {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: 2rem;
    list-style-type: none;
    padding: 0;
    margin: 0 0 2.75rem;

    @include breakpoint-md {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint-xl {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__item {
    max-width: 17rem;
  }

  &__link {
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    flex-direction: column;
    padding: 2.25rem 2rem;
    border: 1px solid transparent;
    text-decoration: none;
    text-align: center;
    color: $color-primary-3;
    font-size: 1rem;
    transition: all 0.15s;

    &:hover {
      border: 1px solid $color-grey-4;
    }

    &:focus {
      background-color: rgba($color-grey-1, 0.5);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75rem;
    height: 2.75rem;
    margin-bottom: 1rem;
    flex-shrink: 0;
    background-color: $color-primary-1;
    border-radius: 50%;

    > svg {
      fill: $color-white;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
