@import "../../styles/variables";
@import '../../styles/mixins/breakpoints';

.form {
  display: flex;
  flex-direction: column;
  align-items: center;


  @include breakpoint-md {
    flex-direction: row;
  }

  &__text {
    max-width: 12.5rem;
    line-height: 0.875rem;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
    text-align: center;

    @include breakpoint-md {
      text-align: left;
      margin: 0 2rem 0 0;
    }
  }

  &__group {
    display: flex;
  }

  &__input {
    height: 2.375rem;
    width: 10.5rem;
    padding: 0 0.75rem;
    margin: 0;
    font-size: 1rem;
    border: 1px solid $color-grey-4;
    border-radius: 0.25rem 0 0 0.25rem;
    font-family: inherit;
    box-sizing: border-box;
    outline: none;
    -webkit-appearance: none;

    &::placeholder {
      color: $color-grey-5;
    }
  }

  &__submit {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.25rem;
    margin: 0;
    background-color: $color-grey-3;
    border-top: 1px solid $color-grey-4;
    border-right: 1px solid $color-grey-4;
    border-bottom: 1px solid $color-grey-4;
    border-left: unset;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    cursor: pointer;
    
    &:disabled {
      cursor: not-allowed;
    }

    > svg {
      fill: $color-primary-1;
    }
  }
}
