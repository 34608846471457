@import "../variables";

// Breakpoint mixins.

@mixin breakpoint-sm {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin breakpoint-md {
  @media (min-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin breakpoint-lg {
  @media (min-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin breakpoint-xl {
  @media (min-width: #{$breakpoint-xl}) {
    @content;
  }
}
