@import '../../styles/variables';

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

html {
  font-family: 'Raleway', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  background-color: $color-page-background;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

// Typography.

h1 {
  font-size: $text-size-xl;
}

h1, h2, h3, h4, h5, h6 {
  color: $color-primary-4;
  margin: 0 0 1rem;
  font-weight: 800;
}

p {
  margin: 0 0 1rem;
  color: $color-text;

  &:last-child {
    margin-bottom: 0;
  }
}

// Buttons.

button {
  font-size: inherit;
  font-family: inherit;
}

// Hide recaptcha badge.

.grecaptcha-badge {
  visibility: hidden;
}
