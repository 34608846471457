@import '../../styles/variables';
@import '../../styles/mixins/breakpoints';

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
  list-style: none;
  margin: 2.5rem 1rem 0;
  padding: 0;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    color: $color-primary-4;
    font-size: 1.25rem;
    font-weight: bold;
    border: 4px solid $color-primary-4;
    background-color: $color-white;
    cursor: pointer;
    text-decoration: none;
    transition: 0.15s background-color;

    &:hover {
      text-decoration: underline;
      background-color: $color-grey-1;
    }
  }
}
