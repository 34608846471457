@import '../../styles/variables';
@import '../../styles/mixins/breakpoints';

.page {
  &__columns {
    display: flex;
    flex-direction: column-reverse;
    border: 2px solid $color-grey-1;
    padding: 1.5rem;
    background-color: $color-white;

    @include breakpoint-md {
      flex-direction: row;
      padding: 2.5rem 3rem;
    }

    @include breakpoint-lg {
      padding: 4.5rem 5.5rem;
    }
  }

  &__column {
    &:nth-child(2) {
      margin-bottom: 2.25rem;
    }

    @include breakpoint-md {
      flex-grow: 1;
      width: 50%;

      &:nth-child(1) {
        margin-right: 2.25rem;
      }

      &:nth-child(2) {
        margin-left: 2.25rem;
        margin-bottom: 0;
      }
    }
  }

  &__title {
    font-weight: 700;
    font-size: 1.25rem;
    color: $color-primary-3;
    margin-bottom: 2rem;
  }

  &__list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0 0 1rem;
    padding: 0;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__link {
    color: $color-text;
    font-size: 0.875rem;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__note {
    display: block;
    font-size: 0.75rem;
    font-weight: 400;
    margin-top: 0.5rem;
    color: $color-grey-6;
  }

  &__icon {
    flex-shrink: 0;
    fill: $color-primary-3;
    width: 1.25rem;
    margin-right: 1rem;
  }

  &__map {
    width: 100%;
    aspect-ratio: 1 / 1;
  }

  &__input,
  &__textarea {
    display: block;
    font-family: inherit;
    font-size: 1rem;
    width: 100%;
    padding: 0.625rem 0.75rem;
    margin-bottom: 1.5rem;
    box-sizing: border-box;
    border: 1px solid $color-grey-4;
    border-radius: 0.125rem;
    outline: none;
    color: $color-text;
    -webkit-appearance: none;

    &::placeholder {
      color: $color-grey-6;
    }
  }

  &__textarea {
    resize: vertical;
  }

  &__submit {
    font-family: inherit;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0.75rem 1.25rem;
    margin: 0;
    background: $color-primary-1;
    border: 1px solid $color-primary-2;
    color: $color-white;
    cursor: pointer;
    outline: none;
    transition: all 0.15s;

    &:hover {
      background: #3b5265;
      border: 1px solid #2e4456;
    }

    &:focus {
      background: #233645;
      border: 1px solid #152532;
    }

    &--loading:disabled {
      position: relative;
      cursor: wait;
      color: transparent;
      user-select: none;
      background-color: $color-grey-4;
      border-color: $color-grey-6;

      &::before {
        display: block;
        position: absolute;
        content: "";
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        border: 2px solid $color-white;
        border-bottom-color: $color-primary-4;
        box-sizing: border-box;
        top: calc(50% - 0.5rem);
        left: calc(50% - 0.5rem);
        animation: loading 2s linear infinite;

        @keyframes loading {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
