@import '../../styles/variables';
@import '../../styles/mixins/breakpoints';

.content {
  // Base text elements.
  p, ul, ol {
    margin-bottom: 1.5rem;
    font-size: 1.125rem;

    @include breakpoint-sm {
      font-size: 1.25rem;
    }
  }

  ul, ol {
    margin: 0 0 1rem;
    padding-left: 2rem;
  }

  p:has(+ ul),
  p:has(+ ol) {
    margin-bottom: 0.5rem;
  }

  a {
    color: $color-blog-link-regular;
    text-decoration: none;

    &:hover {
      color: $color-blog-link-hover;
      text-decoration: underline;
    }

    &:active {
      color: $color-blog-link-active;
      text-decoration: underline;
    }
  }

  // Headers: support only h2 & h3 for now.

  h2 {
    font-size: 1.5rem;

    @include breakpoint-xl {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 1.25rem;

    @include breakpoint-xl {
      font-size: 1.5rem;
    }
  }

  // Misc tags.

  blockquote {
    position: relative;
    font-size: 1rem;
    padding: 0.5rem 1rem 0.5rem 2rem;
    margin: 0.5rem 0;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      content: "";
      width: 0.25rem;
      height: 100%;
      background-color: $color-grey-3;
    }

    @include breakpoint-xl {
      padding: 0.5rem 2rem;
    }
  }

  img {
    width: 100%;
    border-radius: 0.125rem;
  }
}
