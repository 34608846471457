@import '../../styles/variables';
@import '../../styles/mixins/breakpoints';

.section {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  margin-bottom: 2rem;

  &__photo {
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    border-radius: 50%;
    background-color: $color-grey-4;
  }

  &__details {
    display: flex;
    flex-direction: column;
  }

  &__name {
    margin: 0;
    font-size: 1.125rem;
    font-weight: 500;
    color: #000;
   text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__date {
    color: $color-grey-6;
    font-size: 0.875rem;
  }
}