@import '../../styles/variables';
@import '../../styles/mixins/breakpoints';

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 2rem;
  padding: 0;
  list-style-type: none;
  font-size: 1.25rem;

  @include breakpoint-md {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 16.5rem;
    text-align: center;
    margin-bottom: 1.5rem;
    flex-grow: 1;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include breakpoint-md {
      margin-right: 1.5rem;
      margin-bottom: 0;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__photo {
    width: 100%;
    max-height: 17.875rem;
    object-fit: cover;
    margin-bottom: 0.75rem;
    border-radius: 0.125rem;
    aspect-ratio: 12/13;
  }

  &__name {
    font-size: 1.25rem;
    font-weight: 600;
    color: $color-text;
    margin-bottom: 0.5rem;
  }

  &__experience {
    font-weight: 300;
    font-size: 1rem;
  }
}
