@import '../../styles/variables';
@import '../../styles/mixins/breakpoints';

.page {
  &__list {
    margin: 0;
    padding: 0;
  }

  &__item {
    display: flex;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $color-blue-1;
    margin-bottom: 1.5rem;
    font-size: 1.25rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__icon {
    flex-shrink: 0;
    fill: $color-text;
    margin-right: 1.5rem;
    width: 1.25rem;
    height: 1.25rem;
    margin-top: 0.125rem;
  }
}
