// Colours.

$color-white: #fff;
$color-text: #333;
$color-page-background: #FDFDFD;

$color-grey-1: #F0F0F0;
$color-grey-2: #F2F2F2;
$color-grey-3: #EAEBEF;
$color-grey-4: #CFD4D9;
$color-grey-5: #A3A3A3;
$color-grey-6: #757575;

$color-primary-1: #41596C;
$color-primary-2: #374D5F;
$color-primary-3: #444659;
$color-primary-4: #2B3A46;

$color-blue-1: #DFE8EF;
$color-blue-2: #A0E1F2;
$color-blue-3: #77CFE7;
$color-blue-4: #50A2B7;

$color-blog-link-regular: #1A73E8;
$color-blog-link-hover: #1558B0;
$color-blog-link-active: #0E3C78;

// Font sizes.

$text-size-xxs: 0.75rem;
$text-size-xs: 0.875rem;
$text-size-sm: 1rem;
$text-size-rg: 1.25rem;
$text-size-lg: 2.25rem;
$text-size-xl: 3rem;

// Breakpoints.

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// Components.

$header-mobile-height: 5rem;
$header-height: 6.25rem;
$blog-content-max-width: 45rem;
