@import '../../styles/variables';
@import '../../styles/mixins/breakpoints';

.page {
  &__article {
    display: flex;
    flex-direction: column;
    margin: 0 auto 2.5rem;
    max-width: $blog-content-max-width;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include breakpoint-md {
      flex-direction: row;
      gap: 1.5rem;
    }
  }

  &__photo {
    display: flex;
    margin-bottom: 0.5rem;

    img {
      width: 100%;
      max-height: 20rem;
      object-fit: cover;
      border-radius: 0.125rem;
    }

    // 3:2 aspect ratio.
    @include breakpoint-md {
      flex-shrink: 0;
      width: 18rem;
      height: 12rem;
    }
  }

  &__title {
    display: block;
    font-size: 1.25rem;
    font-weight: 700;
    color: $color-primary-4;
    text-decoration: none;
    margin-bottom: 0.25rem;

    @include breakpoint-md {
      font-size: 1.5rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__time {
    display: block;
    color: $color-primary-3;
    font-weight: 500;
    margin-bottom: 1rem;
  }
}
