@import '../../styles/variables';
@import '../../styles/mixins/breakpoints';

.title {
  text-align: center;
  margin-bottom: 2.5rem;
  font-weight: 800;
  font-size: 2rem;

  &--left {
    text-align: left;
  }

  @include breakpoint-xl {
    margin-bottom: 3.75rem;
    font-size: 3rem;
  }
}
