@import '../../styles/variables';
@import '../../styles/mixins/breakpoints';

.page {
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
  animation: slide-in 0.2s ease-in;
  margin-top: $header-mobile-height;

  @include breakpoint-xl {
    margin-top: $header-height;
  }

  &--constrain {
    max-width: 72.5rem;
    margin: 2.5rem + $header-mobile-height auto 2.5rem;
    padding: 0 1.5rem;

    @include breakpoint-md {
      padding: 0 5rem;
    }

    @include breakpoint-xl {
      margin: 3.75rem + $header-height auto 3.75rem;
    }
  }

  @keyframes slide-in {
    from { opacity: 0; }
    to { opacity: 1; }
  }
}
