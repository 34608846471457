@import '../../styles/variables';
@import '../../styles/mixins/breakpoints';

.wrapper {
  position: fixed;
  background-color: $color-white;
  width: 100%;
  height: $header-mobile-height;
  transition: box-shadow 0.15s;
  z-index: 2;

  @include breakpoint-xl {
    height: $header-height;
  }

  &--scrolled {
    box-shadow: 0 0.25rem 0.5rem rgba(87, 112, 133, 0.05);
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 1.5rem;
  height: 100%;
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;

  @include breakpoint-md {
    padding: 0 0 0 5rem;
  }

  // Lef section.

  &__left {
    display: flex;
    align-items: center;
  }

  &__homepage {
    display: flex;
  }

  &__logo {
    color: $color-text;
    width: 8.625rem;

    @include breakpoint-md {
      width: 10.75rem;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding-left: 0.75rem;
    margin-left: 0.75rem;
    border-left: 1px solid $color-primary-4;
  }

  &__name {
    font-weight: 700;
    font-size: 0.625rem;
    line-height: 0.75rem;

    @include breakpoint-md {
      font-size: 0.875rem;
      line-height: 1rem;
      margin-bottom: 0.125rem;
    }
  }

  &__role {
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.75rem;
    color: $color-grey-6;

    @include breakpoint-md {
      font-size: 0.75rem;
      line-height: 0.875rem;
    }
  }

  // Right section.

  &__right {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    border: unset;
    background: none;
    cursor: pointer;
    border-radius: 0.125rem;

    @include breakpoint-md {
      margin-right: 1.5rem;
    }

    @include breakpoint-xl {
      display: none;
    }

    > svg {
      width: 1.75rem;
      height: 1.75rem;
      fill: $color-text;
    }
  }

  &__menu {
    display: none;
    list-style-type: none;
    margin: 0 1rem 0 0;
    padding: 0;

    @include breakpoint-xl {
      display: flex;
    }

    &-item {
      margin-right: 1.5rem;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &-link {
      text-decoration: none;
      text-transform: uppercase;
      color: $color-text;
      font-weight: 500;
      font-size: 0.875rem;
      transition: color 0.15s;

      &:hover {
        color: $color-blue-3;
      }

      &:active {
        color: $color-blue-4;
      }

      &--homepage {
        display: none;

        @media (min-width: 1280px) {
          display: inline;
        }
      }

      &--active {
        position: relative;

        &:after {
          position: absolute;
          bottom: -0.25rem;
          display: block;
          content: "";
          width: 100%;
          height: 0.125rem;
          background-color: $color-blue-2;
        }
      }
    }
  }

  &__contact {
    display: none;
    flex-direction: column;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0 3.75rem 0 1.75rem;
    background-color: $color-primary-1;
    height: 100%;

    @include breakpoint-md {
      display: flex;
    }

    @include breakpoint-xl {
      padding: 0 5rem 0 2.5rem;
    }

    &-item {
      display: flex;
      margin-bottom: 0.5rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &-link {
      display: flex;
      align-items: center;
      color: $color-white;
      text-decoration: none;
      font-size: 0.75rem;
      line-height: 0.875rem;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }

      @include breakpoint-xl {
        font-size: 0.875rem;
        line-height: 1rem;
      }

      > svg {
        fill: $color-white;
        margin-right: 1.25rem;
        width: 0.625rem;
        height: auto;

        @include breakpoint-xl {
          width: 0.875rem;
        }
      }
    }
  }
}
