@import '../../styles/variables';
@import '../../styles/mixins/breakpoints';

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color-text, 0.25);
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: 1.5rem;
  animation: fade-in 0.25s ease;

  &--closing {
    animation: fade-out 0.25s ease forwards;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

.modal {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: $color-white;
  box-sizing: border-box;
  animation: show 0.25s ease;
  overflow-y: auto;
  margin: 0 auto;
  text-align: center;
  max-width: 25rem;

  &--closing {
    animation: close 0.25s ease;
  }

  @keyframes show {
    from {
      transform: translateY(1.5rem);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes close {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-1.5rem);
    }
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    background-color: unset;
    padding: 0;
    margin: 0;
    border: unset;
    cursor: pointer;

    > svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  &__header {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-bottom: 1.25rem;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 0;
    width: 100%;
  }

  &__body {
    flex-grow: 1;
  }
}
