@import "../../styles/variables";
@import '../../styles/mixins/breakpoints';

.wrapper {
  background-color: $color-grey-2;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 90rem;
  margin: 0 auto;
  padding: 1.5rem;
  box-sizing: border-box;

  @include breakpoint-md {
    flex-direction: row;
    padding: 2.5rem 5rem;
  }

  &__list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 1.5rem 0 0;

    @include breakpoint-md {
      margin-top: 0;
    }
  }

  &__item {
    display: flex;
    margin-right: 1.25rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__link {
    display: flex;
    color: $color-primary-1;
    transition: color 0.15s;
    border-radius: 50%;

    &:hover {
      color: $color-primary-4;
    }
  }
}
