@import '../../styles/variables';
@import '../../styles/mixins/breakpoints';

.page {
  font-size: 1.25rem;

  &__text {
    margin-bottom: 1.5rem;
  }
}
