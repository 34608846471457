@import '../../styles/variables';
@import '../../styles/mixins/breakpoints';

$shine-width: 3rem;

.skeleton {
  background-color: $color-grey-3;
  background-image:
          linear-gradient(
                          90deg,
                          rgba(#fff, 0),
                          rgba(#fff, 0.5),
                          rgba(#fff, 0)
          );
  background-size: $shine-width 100%;
  background-repeat: no-repeat;
  background-position: left  -#{$shine-width} top 0;
  animation: shine 1.5s ease infinite;
  border-radius: 0.25rem;

  &--title {
    margin: 0 auto 3.75rem;
    height: 3rem;
    width: 100%;
    max-width: 50rem;
  }

  &--paragraph {
    width: 100%;
    height: 1.75rem;
    margin-bottom: 1.5rem;
  }
}

@keyframes shine {
  to {
    background-position: right -#{$shine-width} top 0;
  }
}