@import '../../styles/variables';
@import '../../styles/mixins/breakpoints';

.page {
  &__title {
    margin-bottom: 2rem;
    font-size: 1.75rem;

    @include breakpoint-xl {
      font-size: 2.5rem;
    }
  }

  &__article {
    margin: 0 auto;
    max-width: $blog-content-max-width;
  }

  &__cover {
    width: 100%;
    max-height: 28.125rem;
    margin-bottom: 2rem;
    border-radius: 0.125rem;
    object-fit: cover;
  }
}
