@import '../../styles/variables';
@import '../../styles/mixins/breakpoints';

.body {
  overflow: hidden;
}

.menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: $header-mobile-height;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-white;
  animation: slide-in 0.2s ease-in;
  z-index: 1;
  overflow-y: auto;

  @keyframes slide-in {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  &__nav {
    flex-grow: 1;
    padding: 2rem 0;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__link {
    text-transform: uppercase;
    color: $color-text;
    text-decoration: none;
    padding: 0.25rem 0.5rem;
    transition: color 0.15s;
    font-weight: 500;
    outline: none;

    &[data-keyboard-focus='true'],
    &:active {
      color: $color-blue-4;
    }

    &:focus:not([data-keyboard-focus='true']) {
      box-shadow: none;
    }

    &:hover {
      color: $color-blue-3;
    }

    &--active {
      font-weight: 700;
    }
  }

  &__contact {
    list-style-type: none;
    margin: 0;
    padding: 2rem;
    background-color: $color-primary-1;
    text-align: center;

    @include breakpoint-md {
      display: none;
    }

    &-item {
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &-link {
      font-weight: 500;
      font-size: 0.875rem;
      text-decoration: none;
      color: $color-white;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
