@import '../../styles/variables';
@import '../../styles/mixins/breakpoints';

$item-height: 18rem;
$item-width: 16.75rem;

.page {
  $page: &;

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: 1.25rem;
    margin: 0;
    padding: 0;
    list-style-type: none;

    @include breakpoint-md {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint-xl {
      grid-template-columns: 1fr 1fr 1fr;
      width: max-content;
      margin: 0 auto;
    }
  }

  &__item {
    justify-self: center;

    @include breakpoint-md {
      &:nth-child(even) {
        justify-self: start;
      }

      &:nth-child(odd) {
        justify-self: end;
      }
    }

    @include breakpoint-xl {
      &:nth-child(even) {
        justify-self: unset;
      }

      &:nth-child(odd) {
        justify-self: unset;
      }
    }
  }

  &__item-skeleton {
    height: $item-height;
    width: $item-width;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: $item-height;
    width: $item-width;
    color: $color-primary-3;
    font-weight: 700;
    font-size: 1.25rem;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    border: 0.125rem solid $color-grey-1;
    padding: 3rem 2.25rem;
    box-sizing: border-box;
    transition: all 0.15s;

    &:hover,
    &:focus {
      outline: none;
      background: $color-blue-2;
      border: 0.125rem solid $color-blue-3;
    }

    > svg {
      flex-shrink: 0;
    }
  }
}
